import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

  {
    path: 'acerca',
    loadChildren: () => import('./acerca/acerca.module').then( m => m.AcercaPageModule)
  },

  {
    path: 'biblio',
    loadChildren: () => import('./biblio/biblio.module').then( m => m.BiblioPageModule)
  },
  
  {
    path: 'enlaces',
    loadChildren: () => import('./enlaces/enlaces.module').then( m => m.EnlacesPageModule)
  },
  
  {
    path: 'articulo/:id',
    loadChildren: () => import('./articulo/articulo.module').then( m => m.ArticuloPageModule)
  },
  {
    path: 'obras',
    loadChildren: () => import('./obras/obras.module').then( m => m.ObrasPageModule)
  },
  {
    path: 'taller',
    loadChildren: () => import('./taller/taller.module').then( m => m.TallerPageModule)
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
