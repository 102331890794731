import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
   
    { title: 'Inicio', url: '/home', icon: 'home' },

    { title: 'Galería', url: '/obras', icon: 'image' },

    { title: 'Enlaces', url: '/enlaces', icon: 'link' },
   
    { title: 'Bibliografía', url: '/biblio', icon: 'library' },
 
    { title: 'Proyecto', url: '/acerca', icon: 'bulb' },

    // { title: 'Taller', url: '/taller', icon: 'create' }
    
  ];
 
  constructor() {}
}
